export const en = {
  ANY: "All",
  CANCELLED: "Cancelled",
  DELIVERED: "Delivered",
  DRAFT: "Draft",
  INACTIVE: "Inactive",
  PAID: "Paid",
  PENDING: "Pending",
  PREPARING: "Preparing",
  addChallenge: "Add Challenge",
  prize: "Prize",
  surveys: "Surveys",
  search_surveys: "Search in surveys",
  addSurvey: "Add Survey",
  surveyType: "Survey Type",
  question: "Survey Question",
  survey_detail: "Survey Detail",
  options: "Survey Options",
  departmentSpecific: "Department Specific",
  type_orpaste_question_here: "Type or paste question here...",
  type_orpaste_prize_here: "Type prize descrition if any",
  challengeType: "Challenge Type",
  challenges: "Challenges",
  search_challenge: "Search in challenges",
  challenge_detail: "Challenge Details",
  post_detail: "Post Detail",
  search_for_a_department: "Search in departments",
  isActive: "Published?",
  deadline: "Deadline",
  PUBLISHED: "Published",
  REQUESTED: "Requested",
  WAITING: "Waiting",
  notes: "Notes:",
  departmentLimited: "is it a department specific event activity?",
  departmentLimitedSurvey: "is it a department specific survey?",
  departments: "Departments",
  addDepartment: "Add Department",
  editDepartment: "Edit Department",
  search_departments: "Search in departments",
  title: "Title",
  postType: "Post Type",
  departmentAtWork: "Department",
  type_orpaste_notes_here: "If you have an extra notes, type them here",
  access_settings: "Access Settings",
  account: "Account",
  accountOverview: "Overview",
  action: "Action",
  actions: "Actions",
  posts: "Posts",
  addPost: "Add Post",
  search_posts: "Search in posts",
  activity: "Activity",
  activityName: "Activity Name",
  addCategory: "Add Category",
  addEventActivity: "Add Event Activity",
  addEventCategory: "Add Event Activity Category",
  addItem: "Add Item",
  addNew: "Add New",
  addSubscription: "Add Subscription",
  add_a_user: "Add a User",
  add_event_activity: "Add an Event Activity",
  add_new_user: "Add New User",
  add_user: "Add User",
  address: "Address",
  address_popup: "After search address will popup here",
  all: "ALL",
  almost_done: "Your Are almost Done!",
  already_account: "Already have an account?",
  apply: "Apply",
  apps: "Apps",
  authentication: "Authentication",
  authorized_admin_info: "Admin and Moderator Accounts for this company",
  authorized_admins: "Authorized Admins",
  back: "Back",
  betterDescription: "Type a description for better visibility.",
  businessHours: "Business Hours",
  calendarApp: "Event Calendar",
  can_change_or_later: "You can change these or add new ones later",
  cancel: "Cancel",
  capacity: "Capacity",
  category: "Category",
  categoryName: "Category Name",
  categoryNamePlaceholder: "Add a category name",
  category_name: "Category Name",
  changelog: "Changelog",
  chat: "Chat",
  choose: "Choose",
  choose_venue: "Choose a venue to manage in live mode",
  city: "City",
  closed: "Closed",
  combine_bill: "Combine Bill",
  complete: "Complete",
  components: "Components",
  confirm_new_password: "Confirm New Password",
  confirm_password: "Confirm Password",
  connected: "Connected",
  connections: "Connections",
  contact_phone: "Contact Phone",
  continue: "Continue",
  country: "Country",
  cover_image: "Cover Image",
  create: "Create",
  createAPIKey: "Create API Key",
  createAccount: "Create Account",
  createApp: "Create App",
  create_an_account: "Create an Account",
  currency: "Currency",
  current_password: "Current password",
  customer: "Customer",
  customerDetails: "Customers Details",
  customer_detail: "Customer Detail",
  customer_see: "Customers will see this version of your statement.",
  customers: "Customers",
  customersListing: "Customers Listing",
  dashboard: "Dashboard",
  date: "Date",
  date_added: "Date Added",
  default_settings: "Default Settings",
  delete: "Delete",
  deleteCompany: "Delete Company",
  delete_all_sure: "The all selected items will be deleted. Are you sure?",
  delete_selected: "Delete selected",
  delete_sure: "The item will be deleted. Are you sure?",
  description: "Description",
  descriptionPlaceHolder: "Type your description here",
  details: "Details",
  discard: "Discard",
  dob: "Date of Birth",
  docsAndComponents: "Docs & Components",
  documentation: "Documentation",
  documents: "Documents",
  downloadReport: "Download Report",
  draft: "Draft",
  drawerChat: "Drawer Chat",
  dropFilesHere: "Drop files here or click to upload files",
  drop_files_here: "Drop files here or click to upload.",
  edit: "Edit",
  editCompany: "Edit Company",
  edit_profile: "Edit Profile",
  edit_company_detail: "Edit venue details",
  editin_company_category: "Editing Venue Category",
  editingEventCategory: "Editing Event Activity Category",
  editing_event_activity: "Editing Event Activity",
  email: "Email",
  end: "End",
  end_date: "End Date",
  enter_location: "Enter a location, mekan name or address",
  enter_password: "Enter your new password.",
  error404: "Error 404",
  error500: "Error 500",
  error_detected:
    "Sorry, looks like there are some errors detected, please try again.",
  eventActivities: "Event Activities",
  eventActivityCategories: "Event Activity Categories",
  eventCalender: "Event Calendar",
  event_activity_category: "Event Activity Category",
  event_activity_name: "Event Activity Name",
  event_date_times: "Event Dates & Times",
  event_location: "Event Location",
  event_location_info:
    "Is the event at the venue which is already created in our Database?",
  events: "Events",
  exampleLink: "Example link",
  excluded: "Excluded",
  export: "Export",
  fav_venues: "Favorite Venues",
  female: "Female",
  first_name: "First Name",
  followers: "Followers",
  for_more_visit: "For more detail visit to https://mypipe.eu",
  forgot_password: "Forgot your password?",
  forgot_password_info: "Enter your email to reset your password.",
  forms: "Forms",
  full_name: "Full Name",
  gender: "Gender",
  general: "General",
  getStarted: "Getting Started",
  gettingStarted: "Getting Started",
  grand_total: "Grand Total",
  groupChat: "Group Chat",
  help_page: "Help Page",
  home: "Home",
  horizontal: "Horizontal",
  iagree: "I Agree & ",
  image_error_format: "Image must be an allowed format!",
  image_error_size: "Image size can not exceed 2MB!",
  images: "Images",
  inactive: "Inactive",
  included: "Included",
  info: "Information",
  inviteFriends: "Invite Friends",
  item: "Item",
  item_name: "Item Name",
  last_3_month: "Last 3 month",
  last_month: "Last month",
  last_name: "Last Name",
  last_week: "Last week",
  latitude: "Latitude",
  list: "List",
  live_session: "Live session",
  loading: "Loading...",
  locationInfo: "Location Info",
  login_success: "You have successfully logged in!",
  longitude: "Longitude",
  male: "Male",
  media: "Media",
  media_success_upload: "Media galerry has been successfully updated!",
  megaMenu: "Mega Menu",
  mekan: "Venue",
  menu: "Menu",
  menus: "Menus",
  modals: "Modals",
  name: "Name",
  need_help: "If you need more info, please check out",
  newAddress: "New Address",
  newCard: "New Card",
  newTarget: "New Target",
  new_here: "New Here?",
  new_password: "New Password",
  newvenue: "New Venue",
  nick_name: "Nickename",
  nickname: "Nickname",
  no: "No",
  no_data: "No data found",
  no_perm:
    "Sorry, looks like you have no priviledge to set the order as cancelled.",
  notPublished: "NotPublished",
  not_a_venue_yet: "You have not add a venue yet",
  not_a_venue_yet_info:
    "Please click new venue wizard on the left menu to create one. Or you have just created one then you should logout and login again to see on the list.",
  notconnected: "Not connected",
  notification: "Notification",
  notifications: "Notifications",
  notpopular: "Not popular",
  notverified: "Not verified",
  open: "Open",
  order: "Order",
  orderBy: "Order By",
  orderDetails: "Order Details",
  orderStatus: "Order Status",
  order_by_payment: "Order by payment status",
  order_detail: "Order Detail",
  order_details: "Order Details",
  order_history: "Order History",
  order_listing: "Order Listing",
  order_note: "Order Note",
  order_report: "Order Report",
  order_status: "Order Status",
  order_summary: "Order Summary",
  orders: "Orders",
  overview: "Overview",
  pages: "Pages",
  password: "Password",
  passwordReset: "Password Reset",
  password_confirmation: "Password Confirmation",
  password_info:
    "Use 8 or more characters with a mix of letters, numbers & symbols.",
  password_match: "Passwords must match",
  password_reset_success: "Password reset have been successfully made!",
  password_success: "Password is successfully changed!",
  payment: "Payment",
  paymentStatus: "Payment Status",
  payment_methods: "Payment Methods",
  payment_status: "Payment Status",
  personal: "Personal",
  phoneNumbers: "Phone Numbers",
  please_wait: "Please wait...",
  popular: "Popular",
  price: "Price",
  print: "Print",
  private: "Private",
  privateChat: "Private Chat",
  privateSponsored: "Private & Sponsored",
  private_event_activiy: "Set the event if it's a private activity.",
  profile: "Profile",
  profileOverview: "Overview",
  profile_detail: "Profile detail",
  published: "Published",
  qrcode: "QR Code",
  qty: "Qty",
  rating: "Rating",
  regularuser: "Regular User",
  regularuser_info:
    "Use this if the user is a regular client and has limited capabilities on the mobile app.",
  reload_map: "Reload Map",
  remove: "Remove",
  report: "Report",
  requireUnique: "This is required and recommended to be unique.",
  required_unique: "This is required and recommended to be unique.",
  reserved: "Reserved",
  reset_password: "Reset Password",
  resetting_password: "Resetting Password",
  resources: "Resources",
  review: "Review",
  review_submit: "Please review and submit the form now.",
  reviews: "Reviews",
  sample_preview: "Table Label Sample Preview",
  save_changes: "Save Changes",
  scan_to_see_more: "Scan to see menu & order",
  search_a_category: "Search for a category",
  search_existing_venue: "Search for an existing venue...",
  search_for_a_user: "Search for a user...",
  search_orders: "Search Orders",
  search_venues: "Search Venues",
  select_a_user: "Search for an existing user...",
  select_categories_for_this_venue: "Select categories for this venue.",
  select_category_appropriate:
    "Select appropriate categories for your new venue.",
  select_country: "Select a country",
  select_currency: "Select a currency for this venue.",
  select_role: "Select User Role",
  select_status: "Select a status",
  select_time_range: "Select a time range",
  select_venue_location: "Select Venue Location",
  selected: "Selected",
  service_fee: "Service Fee",
  service_fee_: "Service fee",
  service_fee_rate_amount: "Service Fee Rate or Amount",
  service_fee_title: "Service fee",
  service_fee_type: "Service Fee Type",
  setStatus: "Set Status",
  set_category_status: "Set the category status.",
  set_item_category: "Set the item category by clicking the catagory name",
  set_media_gallery: "Set the media gallery.",
  set_order_status: "Set Order Status",
  companyNameAtWork: "Company working at",
  set_payment_status: "Set Payment Status",
  set_thumbnail:
    "Set the thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted",
  set_total_seat: "Set the total number of seats for this table.",
  set_venue_status: "Set the venue status.",
  settings: "Settings",
  setup: "Setup",
  shareAndEarn: "Share & Earn",
  signIn: "Sign-in",
  signUp: "Sign-up",
  sign_in: "Sign in",
  sign_in_here: "Sign in here",
  signin: "Sign In",
  socket_disconnected: "Socket connection disconnected!",
  socket_reconnecting: "Socket reconnecting",
  sponsored: "Sponsored",
  sponsored_by: "Sponsored By:",
  sponsored_event_activiy: "Set the event if it's a sponsored activity.",
  start: "Start",
  start_date: "Start Date",
  state: "State",
  status: "is Published?",
  still_need: "We still need your attention!",
  street_detail: "Street address",
  subTotal: "Subtotal",
  submit: "Submit",
  submitted_successfully: "Successfully submitted!",
  subscriptions: "Subscriptions",
  success_new_account: "You have successfully created new account!",
  superadmin: "Super Admin",
  superadmin_info:
    "Use this if the user has full capabilities on the all site administration.",
  supermoderator: "Super Moderator",
  supermoderator_info:
    "Use this if the user has limited capabilities on the all site administration.",
  table: "Table",
  table_1: "Table-1",
  tables: "Tables",
  taxIncluded: "Tax Include",
  taxNumber: "Company Tax Number",
  tax_fee: "Tax Fee",
  tax_included: "is the tax included in prices?",
  tax_info: "Tax Information",
  tax_number: "Tax Number",
  tax_rate: "Tax Rate",
  terms_condition: "Terms and conditions",
  thumbnail: "Thumbnail",
  thumbnail_info:
    "Set the category thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted",
  time: "Time",
  total: "Total",
  staffSize: "Staff Size",
  total_events: "Active Event Groups",
  total_users: "Total Users",
  total_companies: "Total Companies",
  try_again: "Try Again",
  twoFactorAuth: "Two Factor Auth",
  type: "Type",
  type_a_name_here: "Type a name here",
  type_city_name: "Type city name here",
  type_orpaste_desc_here: "Type or paste description here",
  type_state_name: "Type state name here",
  type_street_address: "Type street address here",
  type_tax_number: "Type your tax number here...",
  unit_price: "Unit Price",
  update: "Update",
  update_password: "Update Password",
  update_success: "Updated successfully!",
  upgradePlan: "Upgrade Plan",
  uploadAnyNumber: "Upload any number of files",
  upload_upto: "Upload up to 10 files",
  user: "Client",
  user_listing: "User Listing",
  user_profile: "User Profile",
  user_settings: "User Settings",
  userrole: "User Role",
  users: "Users",
  valid_email: "Please enter a valid email.",
  valid_password:
    "Must Contain 8 Characters, At least One Uppercase, One Lowercase and  One Number.",
  vat: "Vat",
  verified: "Verified",
  vertical: "Vertical",
  view: "View",
  viewSubscription: "View Subscription",
  viewUsers: "View Users",
  view_map: "View Map",
  wait: "Please wait...",
  widgets: "Widgets",
  widgetsCharts: "Charts",
  widgetsFeeds: "Feeds",
  widgetsLists: "Lists",
  widgetsMixed: "Mixed",
  widgetsStatistics: "Statistics",
  widgetsTables: "Tables",
  wifiName: "Wifi Name",
  wifiPassword: "Wifi Password",
  wizards: "Wizards",
  yes: "Yes",
  zipcode: "Zip Code",
  itemsuboptions: "Subitem options",
  sub_itemname: "Item name",
  addSubCategory: "Add a subcategory",
  select_a_category: "Kategori seçiniz",
  filter_by_name: "İsme göre filtrele",
  menuCategoriesTemplate: "Şablon Menü Kategori ve Menüler",
  select_or_search_a_category_info_template:
    "Eklemek istediğiniz kategori ve alt kategorileri ayrı ayrı seçmelisiniz.",
  day: "Gün",
  start_time: "Başlangıç Saati",
  end_time: "Bitiş Saati",
  sunday: "Pazar",
  monday: "Pazartesi",
  tuesday: "Salı",
  wednesday: "Çarşamba",
  thursday: "Perşembe",
  friday: "Cuma",
  saturday: "Cumartesi",
  horoscope: "Horoscope",
  search_users: "Search in users",
  creatingEventsCount: "Events Created",
  participatingEventsCount: "Events Participated",
  points: "Points",
  profileMessage: "Profile Message",
  companies: "Companies",
  link: "Link",
  isactive: "İs Active?",
  isactive_event_activiy: "You can publish or unpublish it.",
  type_orpaste_address_here: "Type the address here",
  userole: "User role",
  endDate: "End Date",
  startDate: "Start Data",
  domains: "Domain Names",
  email_address: "Email Address",
  domain_info:
    "It is necessary for the control of the e-mail addresses that the company users will give when registering on the site.",
  type_a_link_here: "Enter the link address by prefixing it with http://",
  search_for_a_company: "Search for companies",
  my_profile: "My Profile",
  my_settings: "My Settings",
  language: "Language",
  sign_out: "Sign Out",
  email_placeholder: "Type your company e-mail address",
  label: "Label name",
  phone_number: "Phone number with area code",
  eventactivityReviews: "Activity Reviews",
  messages: "Activity Messages",
  attendees: "Attendees",
  eventactivity_detail: "Activity Detail",
  total_attendees: "Total Attendees",
  totalMessages: "Total Messages",
  totalReviews: "Total Reviews",
  eventactivity_id: "Activity Id",
  eventactivity_startdate: "Start Date",
  eventactivity_enddate: "End Date",
  eventactivity_description: "Event Description",
  eventactivity_address: "Event Address",
  pointValue: "Participation Score",
  mediaGallery: "Media Gallery",
  setMediaGallery: "Event Activity Media Gallery",
  eventActivityMessages: "Mesajlar",
  senderName: "Sender",
  createdAt: "Sent at",
  message: "Message",
  filmname: "Film Name",
  userpoints: "User Points",
  isSportActivity: "is it Sport Activity?",
  point_decrease_all_sure:
    "Are you sure that the user'point value will be decrease 5000 points?",
  filmdate: "Film Datetime",
  cinema: "Saloon Name",
  phoneNumber: "Phone Number",
  hasGiven: "Has Given?",
  gifted_user: "User Demands Ticket",
  hasgiven: "Has Given?",
  set_gift_status: "The status of the ticket has been given or not.",
  require: "Required",
  optional: "Optional",
  attendeeList: "Attendee List",
  gift_listing: "Gift Ticket List",
  event_activiy_point: "Participation Point",
  event_activiy_point_info:
    "Represents the point value that the participants will earn",
  personel_mood_report: "Staff Mood Report",
  moodStatus: "Mood",
  search_gifts: "Search...",
  add_gift: "Add Gift Ticket",
  gifts: "Gift Tickets",
  companyPayments: "Company Payments",
  paymentName: "Payment Subject",
  dueDate: "Due Date",
};
