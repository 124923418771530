export const tr = {
  ANY: "Tümü",
  CANCELLED: "İptal edildi",
  DELIVERED: "Teslim edildi",
  DRAFT: "Taslak",
  INACTIVE: "Etkin değil",
  PAID: "Ödenmiş",
  PENDING: "Beklemede",
  PREPARING: "Hazırlanıyor",
  PUBLISHED: "Yayınlandı",
  REQUESTED: "Talep Edildi",
  WAITING: "Beklemede",
  access_settings: "Erişim Ayarları",
  account: "Hesap",
  accountOverview: "Genel bakış",
  action: "Eylem",
  actions: "Eylemler",
  activity: "Etkinlik",
  activityName: "Etkinlik Adı",
  addCategory: "Kateori Ekle",
  addEventActivity: "Etkinlik Ekle",
  addEventCategory: "Etkinlik Kategorisi Ekle",
  addItem: "Öğe Ekle",
  addNew: "Yeni Ekle",
  addSubscription: "Abonelik Ekle",
  add_a_user: "Bir Kullanıcı Ekle",
  add_event_activity: "Bir Etkinlik Ekle",
  add_new_user: "Yeni Kullanıcı Ekle",
  add_user: "Kullanıcı Ekle",
  add_company: "Firma Ekle",
  address: "Adres",
  address_popup: "Aradıktan sonra adres burada açılır",
  all: "TÜMÜ",
  almost_done: "İşiniz Bitmek Üzere!",
  already_account: "Bir hesabınız mı var?",
  apply: "Uygula",
  apps: "Uygulamalar",
  authentication: "Kimlik doğrulama",
  authorized_admin_info: "Bu firmanın Yönetici Hesapları",
  authorized_admins: "Yetkili Yöneticiler",
  back: "Geri",
  betterDescription: "Daha iyi görünürlük için bir açıklama yazınız.",
  businessHours: "İş Saatleri",
  calendarApp: "Etkinlik Takvimi",
  can_change_or_later:
    "Bunları daha sonra değiştirebilir veya yenilerini ekleyebilirsiniz",
  cancel: "İptal",
  capacity: "Kapasite",
  category: "Kategori",
  categoryName: "Kategori Adı",
  categoryNamePlaceholder: "Kategori adı ekleyin",
  category_name: "Kategori Adı",
  changelog: "Değişiklik günlüğü",
  chat: "Sohbet",
  choose: "Seç",
  choose_company: "Canlı modda yönetmek için bir firma seçin",
  city: "Şehir",
  closed: "Kapalı",
  combine_bill: "Faturayı Birleştir",
  complete: "Tamamla",
  components: "Bileşenler",
  confirm_new_password: "Yeni Şifreyi Onayla",
  confirm_password: "Şifreyi Onayla",
  connected: "Bağlısınız",
  connections: "Bağlantılar",
  contact_phone: "İletişim Telefonu",
  continue: "Devam",
  country: "Ülke",
  cover_image: "Kapak Resmi",
  create: "Oluştur",
  createAPIKey: "API Anahtarı Oluştur",
  createAccount: "Hesap Oluştur",
  createApp: "Uygulama Oluştur",
  create_an_account: "Bir Hesap Oluştur",
  currency: "Para birimi",
  current_password: "Mevcut Şifreniz",
  customer: "Müşteri",
  customerDetails: "Müşteri Detayları",
  customer_detail: "Müşteri Ayrıntısı",
  customer_see: "Müşteriler ekstrenizin bu versiyonunu görecek.",
  customers: "Müşteriler",
  customersListing: "Müşteri Listeleme",
  dashboard: "Ön Panel",
  date: "Tarih",
  date_added: "Eklenme Tarihi",
  default_settings: "Varsayılan Ayarlar",
  delete: "Sil",
  deleteCompany: "Firmaı Sil",
  delete_all_sure: "Seçilen tüm öğeler silinecek. Emin misiniz?",
  delete_selected: "Seçilileri sil",
  delete_sure: "Öğe silinecek. Emin misin?",
  description: "Açıklama",
  descriptionPlaceHolder: "Açıklamanızı buraya yazın",
  details: "Ayrıntılar",
  discard: "İptal",
  dob: "Doğum Tarihi",
  docsAndComponents: "Belgeler ve Bileşenler",
  documentation: "Belgeler",
  documents: "Dökümanlar",
  downloadReport: "Raporu İndir",
  draft: "Taslak",
  drawerChat: "Drawer Chat",
  dropFilesHere: "Dosyaları buraya bırakın veya dosya yüklemek için tıklayın",
  drop_files_here: "Dosyaları buraya bırakın veya yüklemek için tıklayın.",
  edit: "Düzenle",
  editCompany: "Firmayı Düzenle",
  edit_profile: "Profili Düzenle",
  edit_company_detail: "Firma ayrıntılarını düzenle",
  editin_company_category: "Firma Kategorisini Düzenleme",
  editingEventCategory: "Etkinlik Kategorisini Düzenleme",
  editing_event_activity: "Etkinlik Düzenleme",
  email: "E-posta",
  end: "Bitiş",
  end_date: "Bitiş Tarihi",
  enter_location: "Bir konum, firma adı veya adres girin",
  enter_password: "Yeni şifrenizi girin.",
  error404: "Hata 404",
  error500: "Hata 500",
  type_orpaste_prize_here: "Bir ödül varsa ilgili detayı buraya yazınız...",
  addChallenge: "Challenge Ekle",
  prize: "Ödül",
  challengeType: "Challenge Tipi",
  challenges: "Challenges",
  surveys: "Anketler",
  search_surveys: "Anketlerde ara",
  addSurvey: "Anket Ekle",
  surveyType: "Anket Tipi",
  question: "Anket Sorusu",
  survey_detail: "Anket Detay",
  options: "Seçenekler",
  departmentSpecific: "Departmana Özel",
  type_orpaste_question_here: "Anket sorusunu buraya yazınız...",
  post_detail: "Gönderi Detayı",
  search_challenge: "Challenge arama",
  isActive: "Yayında mı?",
  deadline: "Bitiş tarihi",
  challenge_detail: "Challenge Detayları",
  notes: "Not:",
  type_orpaste_notes_here: "Ek bir notunuz varsa buraya yazın...",
  posts: "Gönderiler",
  addPost: "Gönderi Ekle",
  search_posts: "Gönderilerde ara",
  error_detected: "Maalesef bazı hatalar algılandı, lütfen tekrar deneyin.",
  eventActivities: "Etkinlikler",
  eventActivityCategories: "Etkinlik Kategorileri",
  eventCalender: "Etkinlik Takvimi",
  event_activity_category: "Etkinlik Kategorisi",
  event_activity_name: "Etkinlik Adı",
  event_date_times: "Etkinlik Tarihi ve Saati",
  event_location: "Etkinlik Konumu",
  event_location_info:
    "Etkinlik, Veritabanımızda zaten oluşturulmuş olan bir firmada mı?",
  events: "Aktiviteler",
  exampleLink: "Örnek bağlantı",
  excluded: "Hariç",
  export: "Dışa aktar",
  fav_companys: "Favori Firmalar",
  female: "Kadın",
  first_name: "Ad",
  followers: "Takip ettiği",
  for_more_visit:
    "Daha fazla ayrıntı için https://SpiceUpApp.com adresini ziyaret edin",
  forgot_password: "Şifrenizi mi unuttunuz?",
  forgot_password_info: "Şifrenizi sıfırlamak için e-posta adresinizi girin.",
  forms: "Formlar",
  full_name: "Tam Ad",
  gender: "Cinsiyet",
  general: "Genel",
  getStarted: "Başlarken",
  gettingStarted: "Başlarken",
  grand_total: "Genel Toplam",
  help_page: "Yardım Sayfası",
  home: "Anasayfa",
  horizontal: "Yatay",
  iagree: "Kabul Ediyorum ",
  image_error_format: "Resim izin verilen bir format olmalı!",
  image_error_size: "Görüntü boyutu 2 MB'ı aşamaz!",
  images: "Görüntüler",
  inactive: "Etkin değil",
  included: "Dahil",
  info: "Bilgilendirme",
  inviteFriends: "Arkadaşlarını Davet Et",
  item: "Öğe",
  item_name: "Öğe Adı",
  last_3_month: "Son 3 ay",
  last_month: "Geçen ay",
  last_name: "Soyad",
  last_week: "Geçen hafta",
  latitude: "Enlem",
  list: "Liste",
  live_session: "Canlı oturum",
  loading: "Yükleniyor...",
  locationInfo: "Konum Bilgisi",
  login_success: "Başarıyla giriş yaptınız!",
  longitude: "Boylam",
  male: "Erkek",
  media: "Medya",
  media_success_upload: "Medya galerisi başarıyla güncellendi!",
  megaMenu: "Mega Menü",
  mekan: "Firma",
  menu: "Menü",
  menus: "Menüler",
  modlar: "Kipler",
  name: "Ad",
  need_help:
    "Daha fazla bilgiye ihtiyacınız varsa, lütfen burayı kontrol edin:",
  newAddress: "Yeni Adres",
  newCard: "Yeni Kart",
  newTarget: "Yeni Hedef",
  new_here: "Burada Yeni misiniz?",
  new_password: "Yeni Şifre",
  newcompany: "Yeni Firma",
  nick_name: "Takma ad",
  nickname: "Takma ad",
  no: "Hayır",
  no_data: "Veri bulunamadı",
  no_perm: "Üzgünüz, siparişi iptal edildi olarak ayarlama ayrıcalığınız yok.",
  notPublished: "Yayında değil",
  not_a_company_yet: "Henüz bir firma yöneticisi değilsiniz.",
  not_a_company_yet_info:
    "Profil kayıtlarınızda bir firmaya ait yönetici kaydınız henüz işleme alınmadığı gözükmektedir. Spiceupapp yetkililer ile iletişime geçiniz.",
  notconnected: "Bağlı değilsiniz",
  notification: "Bildirim",
  notifications: "Bildirimler",
  notpopular: "Popüler değil",
  notverified: "Doğrulanmadı",
  open: "Aç",
  order: "Sipariş",
  orderBy: "Sipariş Veren",
  orderDetails: "Sipariş Ayrıntıları",
  orderStatus: "Sipariş Durumu",
  order_by_payment: "Ödeme durumuna göre siparişler",
  order_detail: "Sipariş Detayı",
  order_details: "Sipariş Ayrıntıları",
  order_history: "Sipariş Geçmişi",
  order_listing: "Sipariş Listesi",
  order_note: "Sipariş Notu",
  order_report: "Sipariş Raporu",
  order_status: "Sipariş Durumu",
  order_summary: "Sipariş Özeti",
  orders: "Siparişler",
  overview: "Genel Bakış",
  pages: "Sayfalar",
  password: "Şifre",
  passwordReset: "Şifre sıfırlama",
  password_confirmation: "Parola Onayı",
  password_info:
    "Harf, sayı ve simge karışımıyla 8 veya daha fazla karakter kullanın.",
  password_match: "Parolalar eşleşmelidir",
  password_reset_success: "Parola sıfırlama başarıyla yapıldı!",
  password_success: "Parola başarıyla değiştirildi!",
  payment: "Ödeme",
  payment_methods: "Ödeme Yöntemleri",
  paymentStatus: "Ödeme Durumu",
  payment_status: "Ödeme Durumu",
  personal: "Kişisel",
  phoneNumbers: "Telefon Numaraları",
  please_wait: "Lütfen bekleyin...",
  popular: "Popüler",
  price: "Fiyat",
  print: "Yazdır",
  private: "Özel",
  privateChat: "Özel Sohbet",
  privateSponsored: "Özel ve Sponsorlu",
  private_event_activiy: "Özel bir etkinlikse etkinliği ayarlayın.",
  profile: "Profil",
  profileOverview: "Genel bakış",
  profile_detail: "Profil detayı",
  published: "Yayınlandı",
  qrcode: "QR Kodu",
  qty: "Adet",
  rating: "Derecelendirme",
  regularuser: "Normal Kullanıcı",
  regularuser_info:
    "Kullanıcı normal bir istemciyse ve mobil uygulamada sınırlı yeteneklere sahipse bunu kullanın.",
  reload_map: "Haritayı Yeniden Yükle",
  remove: "Kaldır",
  report: "Rapor",
  requireUnique: "Bunun benzersiz olması gereklidir ve önerilir.",
  required_unique: "Bunun benzersiz olması zorunludur ve önerilir.",
  reserved: "Ayrılmış",
  reset_password: "Şifreyi Sıfırla",
  resetting_password: "Şifre Sıfırlanıyor",
  resources: "Kaynaklar",
  review: "İnceleme",
  review_submit:
    "Gerekirse fütfen formu şimdi tekrar inceleyin ve sonra gönderim yapın.",
  reviews: "İnceleme & Değerleme",
  sample_preview: "Masa Etiketi Örneği Önizlemesi",
  save_changes: "Değişiklikleri Kaydet",
  scan_to_see_more: "Menüyü görmek ve sipariş takibi için tarayınız",
  search_a_category: "Kategori ara",
  search_existing_company: "Mevcut bir firma arayın...",
  search_for_a_user: "Bir kullanıcı ara...",
  search_orders: "Siparişleri Ara",
  search_companys: "Firmalarda Ara",
  select_a_user: "Mevcut bir kullanıcıyı ara...",
  select_categories_for_this_company: "Bu firma için kategorileri seçin.",
  select_category_available: "Yeni firmanız için uygun kategorileri seçin.",
  select_country: "Bir ülke seçin",
  select_currency: "Bu firma için bir para birimi seçin.",
  select_role: "Kullanıcı Rolünü Seç",
  select_status: "Bir durum seçin",
  select_time_range: "Bir zaman aralığı seçin",
  select_company_location: "Firma Konumunu Seç",
  selected: "Seçildi",
  service_fee: "Hizmet Bedeli",
  service_fee_: "Servis ücreti",
  service_fee_rate_amount: "Hizmet Ücreti Oranı veya Tutarı",
  service_fee_title: "Servis ücreti",
  service_fee_type: "Hizmet Ücreti Türü",
  setStatus: "Durum Ayarı",
  set_category_status: "Kategori durumunu ayarlayın.",
  set_item_category: "Kategori ismine tıklayarak öğe kategorisini ayarlayın",
  set_media_gallery: "Medya galerisini ayarlayın.",
  set_order_status: "Sipariş Durumunu Ayarla",
  set_payment_status: "Ödeme Durumunu Ayarla",
  companyNameAtWork: "Çalıştığı Firma",
  departmentAtWork: "Çalıştığı Departman",
  search_for_a_department: "Departmanları seç",
  departmentLimited: "Departmana Özel Etkinlik mi?",
  departmentLimitedSurvey: "Departmana Özel Anket mi?",
  set_thumbnail:
    "Kapak resmi ayarlayın. Yalnızca *.png, *.jpg ve *.jpeg resim dosyaları kabul edilir",
  set_total_seat: "Bu masa için toplam koltuk sayısını ayarlayın.",
  set_company_status: "Firma durumunu ayarlayın.",
  settings: "Ayarlar",
  setup: "Kurulum",
  shareAndEarn: "Paylaş ve Kazan",
  signIn: "Giriş",
  signUp: "Kaydolma",
  sign_in: "Oturum aç",
  sign_in_here: "Buradan oturum açın",
  signin: "Oturum Aç",
  socket_disconnected: "Soket bağlantısı kesildi!",
  socket_reconnecting: "Soket yeniden bağlanıyor",
  sponsored: "Sponsorlu",
  sponsored_by: "Sponsor:",
  sponsored_event_activiy: "Sponsorlu bir etkinlikse etkinliği ayarlayın.",
  start: "Başlama",
  start_date: "Başlangıç Tarihi",
  state: "Bölge",
  status: "Yayında mı?",
  still_need: "Hala ilginize ihtiyacımız var!",
  street_detail: "Sokak adresi",
  subTotal: "Ara toplam",
  submit: "Gönder",
  submitted_successfully: "Başarıyla gönderildi!",
  subscriptions: "Abonelik Listesi",
  success_new_account: "Başarıyla yeni bir hesap oluşturdunuz!",
  superadmin: "Süper Yönetici",
  superadmin_info:
    "Kullanıcının tüm site yönetiminde tam yetenekleri varsa bunu kullanın.",
  supermoderator: "Süper Moderatör",
  supermoderator_info:
    "Kullanıcının tüm site yönetiminde sınırlı yetenekleri varsa bunu kullanın.",
  table: "Tablo",
  tables: "Masalar",
  table_1: "Tablo-1",
  taxIncluded: "Vergi Dahil mi?",
  taxNumber: "Firma Vergi Numarası",
  tax_fee: "Vergi Ücreti",
  tax_included: "KDV dahil mi?",
  tax_info: "Vergi Bilgileri",
  tax_number: "Vergi Numarası",
  tax_rate: "Vergi Oranı",
  terms_condition: "şartlar ve koşulları",
  thumbnail: "Kapak resmi",
  thumbnail_info:
    "Kategori kapak resmini ayarlayın. Yalnızca *.png, *.jpg ve *.jpeg resim dosyaları kabul edilir",
  time: "Zaman",
  total: "Toplam",
  staffSize: "Çalışan Sayısı",
  total_events: "Aktif Etkinlik Grupları",
  total_users: "Toplam Kullanıcılar",
  total_companys: "Toplam Firmalar",
  try_again: "Tekrar Dene",
  twoFactorAuth: "İki Faktör Yetkilendirme",
  type: "Tür",
  departments: "Departmanlar",
  addDepartment: "Departman Ekle",
  editDepartment: "Departman Düzenle",
  search_departments: "Departmanlarda ara",
  title: "Başlık",
  postType: "Gönderi Tipi",
  type_a_name_here: "Buraya bir ad yazın",
  type_city_name: "Şehir adını buraya yazın",
  type_orpaste_desc_here: "Açıklamayı buraya yazın veya yapıştırın",
  type_state_name: "Durum adını buraya yazın",
  type_street_address: "Sokak adresini buraya yazın",
  type_tax_number: "Vergi numaranızı buraya yazın...",
  unit_price: "Birim Fiyat",
  update: "Güncelleme",
  update_password: "Şifreyi Güncelle",
  update_success: "Başarıyla güncellendi!",
  upgradePlan: "Yükseltme Planı",
  uploadAnyNumber: "İstediğiniz sayıda dosya yükleyin",
  upload_upto: "10 dosyaya kadar yükleyin",
  user: "Kullanıcı",
  user_listing: "Kullanıcı Listesi",
  user_profile: "Kullanıcı Profili",
  user_settings: "Kullanıcı Ayarları",
  userrole: "Kullanıcı Rolü",
  users: "Kullanıcılar",
  valid_email: "Lütfen geçerli bir e-posta girin.",
  valid_password:
    "8 Karakter, En Az Bir Büyük Harf, Bir Küçük Harf ve Bir Rakam İçermelidir.",
  vat: "KDV",
  company: "Firma",
  companyCategories: "Firma Kategorileri",
  companyMediaGallery: "Firma Medya Galerisi",
  companyName: "Firma Adı",
  companyOrders: "Firma Siparişleri",
  companyReviews: "Firma İncelemeleri",
  company_address: "Firma Adresi",
  company_admin: "Yöneticisi olduğu firmalar",
  company_admin_info: "Bu firma için yönetici olarak bir kullanıcı seçin.",
  company_appropriate: "Bu firma için uygun kategorileri seçin.",
  company_category: "Firma Kategorisi",
  company_coordinates: "Firma Koordinatları",
  company_currency: "Firma Para Birimi",
  company_description: "Firma Açıklaması",
  company_detail: "Firma Ayrıntısı",
  company_id: "Firma Kimliği",
  company_info: "Firma Bilgileri",
  company_listing: "Firma Listesi",
  company_listing_info:
    "Yalnızca süper yöneticiler firmaların uygulama listelerinde yayınlanmasını sağlayabilir.",
  company_location: "Firma Konumu",
  company_moderator: "Firma Moderatörü",
  total_companies: "Toplam Firmalar",
  company_moderator_info:
    "Kullanıcının Firma yönetiminde sınırlı yetenekleri varsa bunu kullanın.",
  company_name: "Firma Adı",
  company_price_info: "Bu firma için uygun para birimini seçin.",
  company_street_address: "Firma sokak adresi",
  companylive: "Firma Canlı",
  companys: "Firmalar",
  verified: "Doğrulandı",
  vertical: "Dikey",
  view: "Görüntüle",
  viewSubscription: "Aboneliği Görüntüle",
  viewUsers: "Kullanıcıları Görüntüle",
  view_map: "Haritayı Görüntüle",
  wait: "Lütfen bekleyin...",
  widgets: "Widgets",
  widgetsCharts: "Grafikler",
  widgetsFeeds: "Feed'ler",
  widgetsKarışık: "Karışık",
  widgetsLists: "Listeler",
  widgetsStatistics: "İstatistik",
  widgetsTables: "Tablolar",
  wifiName: "Wifi Adı",
  wifiPassword: "Wifi Şifre",
  wizards: "Sihirbazlar",
  yes: "Evet",
  zipcode: "Posta Kodu",
  itemsuboptions: "Alt öğe seçenekleri",
  sub_itemname: "Adı",
  addSubCategory: "Alt kategori ekle",
  select_a_category: "Kategori seçiniz",
  filter_by_name: "İsme göre filtrele",
  menuCategoriesTemplate: "Şablon Menü Kategori ve Menüler",
  select_or_search_a_category_info_template:
    "Eklemek istediğiniz kategori ve alt kategorileri ayrı ayrı seçmelisiniz.",
  day: "Gün",
  start_time: "Başlangıç Saati",
  end_time: "Bitiş Saati",
  sunday: "Pazar",
  monday: "Pazartesi",
  tuesday: "Salı",
  wednesday: "Çarşamba",
  thursday: "Perşembe",
  friday: "Cuma",
  saturday: "Cumartesi",
  horoscope: "Burçlar",
  search_users: "İsme göre filtrele",
  creatingEventsCount: "Oluşturduğu Etkinlik",
  participatingEventsCount: "Katıldığı Etkinlik",
  points: "Puan",
  profileMessage: "Profil Mesajı",
  companies: "Firmalar",
  link: "Bağlantı",
  isactive: "Yayında mı?",
  isactive_event_activiy: "Yayına alabilir yada kaldırabilirsiniz.",
  type_orpaste_address_here: "Adresi buraya yazınız",
  userole: "Kullanıcı yetkisi",
  endDate: "Bitiş",
  startDate: "Başlangıç",
  domains: "Alan Adları",
  email_address: "E-posta Adresi",
  domain_info:
    "Firma kullanıcılarının siteye kayıt olurken verecekleri eposta adreslerinin kontrolü için gereklidir.",
  type_a_link_here: "Bağlantı adresini başına http:// ekleyerek giriniz",
  search_for_a_company: "Firma ara",
  my_profile: "Profilim",
  my_settings: "Ayarlarım",
  language: "Site Dili",
  sign_out: "Çıkış",
  email_placeholder: "Firma e-posta adresini yazınız",
  label: "Etiket adı",
  phone_number: "Telefon numarası alan kodu ile birlikte",
  eventactivityReviews: "Etkinlik İncelemeleri",
  messages: "Etkinlik Mesajları",
  attendees: "Katılımcılar",
  eventactivity_detail: "Etkinlik Detayı",
  total_attendees: "Toplam Katılımcı",
  totalMessages: "Toplam Mesaj",
  totalReviews: "Toplam İnceleme",
  eventactivity_id: "Etkinlik No",
  eventactivity_startdate: "Etkinlik Başlama",
  eventactivity_enddate: "Etkinlik Bitiş",
  eventactivity_description: "Etkinlik Açıklama",
  eventactivity_address: "Etkinlik Adresi",
  pointValue: "Katılım Puanı",
  mediaGallery: "Foto Galeri",
  setMediaGallery: "Etkinlik Foto Galerisi",
  eventActivityMessages: "Mesajlar",
  senderName: "Gönderen",
  createdAt: "Gönderim Zamanı",
  message: "Mesaj",
  gifts: "Hediye Bilet",
  add_gift: "Hediye Bilet Ekle",
  update_gift: "Hediye Bilet Güncelleme",
  search_gifts: "Arama...",
  filmname: "Film Adı",
  filmdate: "Film Tarih Saat",
  cinema: "Sinema Solanu",
  userpoints: "Kullanıcı puanı",
  phoneNumber: "Telefon",
  hasgiven: "Verildimi ?",
  point_decrease_all_sure:
    "Kullanıcı puanında 5000 puan silinecektir? Emin misin?",
  gifted_user: "Bilet Talep Eden",
  hasGiven: "Verildi mi?",
  set_gift_status: "Biletin verilip verilmediği durumu",
  require: "Zorunlu",
  optional: "Opsiyonel",
  isSportActivity: "Spor etkinliği mi?",
  gift_listing: "Hediye Bilet Listesi",
  attendeeList: "Katılımcı Listesi",
  event_activiy_point: "Katılım Puanı",
  event_activiy_point_info:
    "Katılımcıların kazanacakları puan değerini ifade eder",
  personel_mood_report: "Duygu Durumu Raporu",
  moodStatus: "Ruh Hali",
  companyPayments: "Firma Ödemeleri",
  paymentName: "Ödeme Konusu",
  dueDate: "Ödeme Tarihi",
};
