import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/SystemLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/eventactivitycategory/listing",
        name: "apps-eventactivitycategory-listing",
        component: () =>
          import(
            "@/views/apps/eventscategory/EventActivityCategoryListing.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/eventactivity/listing",
        name: "apps-eventactivity-listing",
        component: () => import("@/views/apps/events/EventActivityListing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/eventactivity/detail/:eventactivityId",
        name: "apps-eventactivity-detail",
        component: () => import("@/views/apps/events/EventActivityDetail.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/posts/listing",
        name: "apps-posts-listing",
        component: () => import("@/views/apps/post/PostListing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/posts/detail/:postId",
        name: "apps-posts-detail",
        component: () => import("@/views/apps/post/PostDetail.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/surveys/listing",
        name: "apps-surveys-listing",
        component: () => import("@/views/apps/survey/SurveyListing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/surveys/detail/:surveyId",
        name: "apps-surveys-detail",
        component: () => import("@/views/apps/survey/SurveyDetail.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/challenges/listing",
        name: "apps-challenges-listing",
        component: () => import("@/views/apps/challenge/ChallengeListing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/challenges/detail/:challengeId",
        name: "apps-challenges-detail",
        component: () => import("@/views/apps/challenge/ChallengeDetail.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/companies/listing",
        name: "apps-companies-listing",
        component: () => import("@/views/apps/companies/CompanyListing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/companies/company-detail/:companyId",
        name: "apps-companies-detail",
        component: () => import("@/views/apps/companies/CompanyDetails.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/companies/company-report/:companyId",
        name: "apps-companies-report",
        component: () =>
          import("@/components/company/cards/report/Companyreport.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/gifts/gift-listing",
        name: "apps-gift-listing",
        component: () => import("@/views/apps/gifts/GiftListing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/users/users-listing",
        name: "apps-users-listing",
        component: () => import("@/views/apps/users/UsersListing.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/users/user-account",
        name: "apps-user-profile-account",
        component: () => import("@/views/apps/users/UserAccount.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "overview",
            name: "user-overview",
            component: () => import("@/views/apps/users/UserOverview.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "settings",
            name: "user-settings",
            component: () => import("@/views/apps/users/UserSettings.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/apps/event-calendar",
        name: "apps-event-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/AuthLayout.vue"),
    children: [
      {
        path: "/role-checking",
        name: "role-checking",
        component: () => import("@/views/RoleCheck.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/SignUp.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("@/views/authentication/ResetPassword.vue"),
      },
      {
        path: "/verify-email",
        name: "verify-email",
        component: () => import("@/views/authentication/VerifyEmail.vue"),
      },
      {
        path: "/welcome",
        name: "welcome",
        component: () => import("@/views/authentication/Welcome.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isUserAuthenticated) {
      router.push({ name: "sign-in" });
    } else {
      next();
    }
  } else {
    next();
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
