import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { IUser } from "@/core/data/imypipe";

interface StoreInfo {
  userdisplayed: IUser;
}

@Module
export default class UserModule extends VuexModule implements StoreInfo {
  userdisplayed = {} as IUser;

  /**
   * Get current user data
   * @returns object
   */
  get getUserDisplayed(): IUser {
    return this.userdisplayed;
  }

  @Mutation
  [Mutations.SET_USER_DISPLAY](payload) {
    this.userdisplayed = payload;
  }

  @Action
  [Actions.SET_CURRENT_USER_DISPLAY](payload) {
    this.context.commit(Mutations.SET_USER_DISPLAY, payload);
  }
}
