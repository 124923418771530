import { Actions } from "@/store/enums/StoreEnums";
import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store/index";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    if (store.getters.userRole) {
      ApiService.vueInstance.axios.defaults.baseURL =
        store.getters.userRole === "superadmin" ||
        store.getters.userRole === "supermoderator"
          ? process.env.VUE_APP_API_URL
          : process.env.VUE_APP_API_URL + store.getters.userRole + "/";
    } else {
      ApiService.vueInstance.axios.defaults.baseURL =
        process.env.VUE_APP_API_URL;
    }

    ApiService.vueInstance.axios.interceptors.response.use(
      async (response) => response,
      async (error) => {
        const originalConfig = error.config;
        if (error.response) {
          // Access Token was expired
          if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              console.log("Refresh authentication");
              await store.dispatch(Actions.REFRESH_AUTH);
              return ApiService.vueInstance.axios(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
          console.log(
            "error.response.status:",
            error.response.status,
            "originalConfig._retry:",
            originalConfig._retry
          );

          if (error.response.status === 401 && originalConfig._retry) {
            console.log("I am tryign to logout");
            await store.dispatch(Actions.LOGOUT);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    if (store.getters.userRole) {
      ApiService.vueInstance.axios.defaults.baseURL =
        store.getters.userRole === "superadmin" ||
        store.getters.userRole === "supermoderator"
          ? process.env.VUE_APP_API_URL
          : process.env.VUE_APP_API_URL + store.getters.userRole + "/";
    } else {
      ApiService.vueInstance.axios.defaults.baseURL =
        process.env.VUE_APP_API_URL;
    }
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.getters.accessToken}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: unknown
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig | Record<string, unknown>
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
