
import { defineComponent, ref } from "vue";
import draggable from "vuedraggable";

interface IHeaderConfiguration {
  name?: string;
  key: string;
  class?: string;
  sortingField?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "kt-datatable-recursive",
  emits: ["input", "enddragging"],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    value: { type: Array, required: false, default: null },
    list: {
      required: false,
      type: Array,
      default: null,
    },
    hasChildren: { type: Boolean, default: false },
  },
  components: { draggable },
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
  },
  setup(props, { emit }) {
    const dragging = ref(false);
    const endDragging = () => {
      dragging.value = false;
      emit("enddragging");
    };
    const onChange = () => {
      if (props.list && Array.isArray(props.list) && props.list.length > 0) {
        props.list.map((item: any, index) => {
          const newSort = index + 1;
          // also add in a new property called has changed if you want to style them / send an api call
          item.hasChanged = item.sortIndex !== newSort;
          if (item.hasChanged) {
            item.sortIndex = newSort;
          }
          return item;
        });
      }
    };
    return { dragging, onChange, endDragging };
  },
});
