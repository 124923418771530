import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { ICompany } from "@/core/data/imypipe";

interface StoreInfo {
  company: ICompany;
}

@Module
export default class CompanyModule extends VuexModule implements StoreInfo {
  company = {} as ICompany;

  /**
   * Get current mekan data
   * @returns object
   */
  get getCompany(): ICompany {
    return this.company;
  }

  @Mutation
  [Mutations.SET_COMPANY](payload) {
    this.company = payload;
  }

  @Action
  [Actions.SET_CURRENT_COMPANY](payload) {
    this.context.commit(Mutations.SET_COMPANY, payload);
  }
}
