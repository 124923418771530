import isPast from "date-fns/isPast";
import parseISO from "date-fns/parseISO";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  ITokens,
  IUser,
  IUserAuthInfo,
  IUserGeolocation,
} from "@/core/data/imypipe";

@Module
export default class AuthModule extends VuexModule implements IUserAuthInfo {
  errors = "";
  user = {} as IUser;
  tokens = {} as ITokens;
  isAuthenticated = false;
  isSocketConnected = false;
  currentUserGeolocation = {} as IUserGeolocation;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): IUser {
    return this.user;
  }

  /**
   * Get current user role
   * @returns string
   */
  get userRole(): string {
    return this.user.role;
  }

  /**
   * Get current socket status
   * @returns Boolean
   */

  get socketStatus(): boolean {
    return this.isSocketConnected;
  }
  /**
   * Get current access token
   * @returns string
   */
  get accessToken(): string {
    return this.tokens?.access?.token;
  }

  /**
   * Get current access token expires
   * @returns string
   */
  get accessExpire(): string {
    return this.tokens?.access?.expires;
  }

  /**
   * Get current refresh token
   * @returns string
   */
  get refreshToken(): string {
    return this.tokens?.refresh?.token;
  }

  /**
   * Get current refresh token expires
   * @returns string
   */
  get refreshExpire(): string {
    return this.tokens?.refresh?.expires;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
   * Get current user geolocation
   * @returns object
   */
  get getCurrentUserGeolocation() {
    return this.currentUserGeolocation;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_SOCKET_STATUS](status) {
    this.isSocketConnected = status;
  }
  @Mutation
  [Mutations.SET_GEOLOACTION](geolocation) {
    this.currentUserGeolocation = geolocation;
  }

  @Mutation
  [Mutations.SET_REFRESH_TOKEN](data) {
    this.isAuthenticated = true;
    this.errors = "";
    this.tokens = data;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.tokens = data.tokens;
    this.errors = "";
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as IUser;
    this.tokens = {} as ITokens;
    this.errors = "";
  }

  @Action
  [Actions.SET_GEOLOACTION](payload) {
    this.context.commit(Mutations.SET_GEOLOACTION, payload);
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      ...credentials,
    };
    return ApiService.post("auth/login", params)
      .then(({ data }) => {
        console.log("data:", data);
        if (["superadmin", "companyadmin"].includes(data.user.role)) {
          this.context.commit(Mutations.SET_AUTH, data);
        } else {
          this.context.commit(
            Mutations.SET_ERROR,
            "Admin Bölümüne Girişte Yetkili Değilsiniz!"
          );
        }
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    const refreshToken = this.context.getters.refreshToken;
    if (refreshToken) {
      console.log("loging out");
      return ApiService.post("auth/logout", { refreshToken })
        .then(() => {
          this.context.commit(Mutations.PURGE_AUTH);
          console.log("logged out");
          window.location.reload();
        })
        .catch(({ response }) => {
          console.log("logged out error, response:", response);
          this.context.commit(Mutations.SET_ERROR, response.data.message);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      return false;
    }
  }

  @Action
  [Actions.REGISTER](credentials) {
    const params = {
      ...credentials,
    };
    return ApiService.post("auth/registerAdmin", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.ADD_NEW_USER](credentials) {
    ApiService.setHeader();
    const params = {
      ...credentials,
    };
    return ApiService.post("users", params)
      .then(({ data }) => data)
      .catch(({ response }) => {
        console.log(JSON.stringify(response, null, 2));
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.GET_USERS](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    ApiService.setHeader();
    return ApiService.query("users", params)
      .then(({ data }) => data)
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("auth/forgot-password", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.REFRESH_AUTH]() {
    const refresh_token = this.context.getters.refreshToken || null;
    const refresh_token_expires = this.context.getters.refreshExpire || null;

    if (
      refresh_token &&
      refresh_token_expires &&
      !isPast(parseISO(refresh_token_expires))
    ) {
      ApiService.setHeader();
      const refreshToken = refresh_token;
      console.log("refreshing tokens");
      ApiService.post("/auth/refresh-tokens", { refreshToken })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REFRESH_TOKEN, data);
        })
        .catch(({ response }) => {
          console.log("refresh token error response:", response);
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    const token = this.context.getters.accessToken;
    const token_expires = this.context.getters.accessExpire;
    const refresh_token = this.context.getters.refreshToken;
    const refresh_token_expires = this.context.getters.refreshExpire;

    if (token) {
      if (
        token_expires &&
        refresh_token &&
        refresh_token_expires &&
        isPast(parseISO(token_expires)) &&
        !isPast(parseISO(refresh_token_expires))
      ) {
        this.context.dispatch(Actions.REFRESH_AUTH);
      }
      if (
        token_expires &&
        refresh_token_expires &&
        isPast(parseISO(token_expires)) &&
        isPast(parseISO(refresh_token_expires))
      ) {
        this.context.commit(Mutations.PURGE_AUTH);
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](data) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.update("users", data.id, data.payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
  @Action
  [Actions.RESET_PASSWORD](payload) {
    const params = { password: payload.password };
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`auth/reset-password/?token=${payload.token}`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
  @Action
  [Actions.UPDATE_SOCKET_STATUS](payload) {
    this.context.commit(Mutations.SET_SOCKET_STATUS, payload);
  }
}
