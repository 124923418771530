
import { defineComponent, nextTick, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    let watcher = 0;

    onMounted(() => {
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
    onUnmounted(() => {
      if (watcher) navigator.geolocation.clearWatch(watcher);
    });
  },
});
