enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  REFRESH_AUTH = "refreshAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  ADD_NEW_USER = "addNewUser",
  GET_USERS = "getUsers",
  GET_MEKAN = "getMekan",
  SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY",
  SET_CURRENT_EVENTACTIVITY = "SET_CURRENT_EVENTACTIVITY",
  SET_CURRENT_USER_DISPLAY = "SET_CURRENT_USER_DISPLAY",
  SET_GEOLOACTION = "addGeolocation",
  UPDATE_SOCKET_STATUS = "UPDATE_SOCKET_STATUS",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_REFRESH_TOKEN = "setRefreshToken",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_COMPANY = "setMekan",
  SET_EVENTACTIVITY = "setEventActivity",
  SET_USER_DISPLAY = "setUserDisplay",
  SET_GEOLOACTION = "setGeolocation",
  SET_SOCKET_STATUS = "SET_SOCKET_STATUS",
}

export { Actions, Mutations };
