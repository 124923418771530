import { createI18n } from "vue-i18n";
import { en } from "@/core/helpers/languages/en";
import { tr } from "@/core/helpers/languages/tr";

const messages = {
  en: en,
  tr: tr,
};

const i18n = createI18n({
  legacy: false,
  locale: "tr",
  globalInjection: true,
  warnHtmlMessage: false,
  messages,
});

export default i18n;
