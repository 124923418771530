import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import Maska from "maska";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Socketio from "@/core/plugins/socketio";
import KTRecursive from "@/components/kt-datatable/KTDatatable-Recursive.vue";
import Tr from "element-plus/dist/locale/tr.mjs";
import "bootstrap";
import "dayjs/locale/tr";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus, { locale: Tr });
app.use(Maska);
app.use(Socketio, {
  connection: process.env.VUE_APP_SOCKET_NET_ADR,
  options: {
    autoConnect: false,
  },
});
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.component("KTRecursive", KTRecursive);
app.mount("#app");
