import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { IEventActivity } from "@/core/data/imypipe";

interface StoreInfo {
  eventactivity: IEventActivity;
}

@Module
export default class EventActivityModule
  extends VuexModule
  implements StoreInfo
{
  eventactivity = {} as IEventActivity;

  /**
   * Get current mekan data
   * @returns object
   */
  get getEventactivity(): IEventActivity {
    return this.eventactivity;
  }

  @Mutation
  [Mutations.SET_EVENTACTIVITY](payload) {
    this.eventactivity = payload;
  }

  @Action
  [Actions.SET_CURRENT_EVENTACTIVITY](payload) {
    this.context.commit(Mutations.SET_EVENTACTIVITY, payload);
  }
}
