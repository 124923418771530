import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import createPersistedState from "vuex-persistedstate";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import CompanyModule from "@/store/modules/CompanyModule";
import UserModule from "@/store/modules/UserModule";
import ConfigModule from "@/store/modules/ConfigModule";
import EventActivityModule from "./modules/EventActivityModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    CompanyModule,
    EventActivityModule,
    UserModule,
    ConfigModule,
  },
  plugins: [createPersistedState({ key: "SpiceUpApp" })],
});

export default store;
